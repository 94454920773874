<template>
  <page title="在线客服">
    <cell note="sw39293" url="#">微信客服</cell>
    <cell note="39344833" url="#">客服QQ</cell>
  </page>
</template>

<script>
export default {
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>